<template>
    <vx-card :title="'View Handover Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Vehicle Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="vehicleNumber" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Driver</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="driverName" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(handoverDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe style="width:100%" :data="skuLines">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle;">Sku Code</th>
                            <th style="vertical-align: middle;">Item</th>
                            <th style="vertical-align: middle;">Unit</th>
                            <th style="vertical-align: middle;">Amount</th>
                            <th style="vertical-align: middle;">Amount Receive</th>
                            <th style="vertical-align: middle;">Amount Return</th>
                            <th style="vertical-align: middle;">Handed-over</th>
                        </tr>
                    </thead>
                    <template slot-scope="">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in skuLines">
                            <vs-td>{{ tr.sku_code }}</vs-td>
                            <vs-td>{{ tr.item_name }}</vs-td>
                            <vs-td>{{ tr.unit }}</vs-td>
                            <vs-td>{{ tr.amount }}</vs-td>
                            <vs-td>{{ tr.amount_receive }}</vs-td>
                            <vs-td>{{ tr.amount_return }}</vs-td>
                            <vs-td>
                                <vs-checkbox
                                    :checked="checked.includes(tr.line_id)"
                                    :disabled="true"
                                    v-model="tr.handedover"
                                ></vs-checkbox>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            status: null,
            handoverCode: null,
            vehicleNumber: null,
            driverName: null,
            handoverDate: null,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    amount: 0,
                    amount_receive: 0,
                    amount_return: 0,
                    handedover: false,
                }
            ],
            checked: [],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-planner/handover-detail/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.status = resp.data.status
                        this.handoverCode = resp.data.handover_code
                        this.vehicleNumber = resp.data.vehicle_number
                        this.driverName = resp.data.driver_name
                        this.handoverDate = resp.data.handover_date
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                        console.log(resp.message);
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-planner",
                params: {status: 3}
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>